<template>
  <h4 class="text-2xl py-2">+83 <span class="update_date">(2024.10.07)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>에어솔루션 모하비 FOTA PGM (SAA44779303, SAA44779304) 추가되었습니다.</li>
  </ul>
  <h4 class="text-2xl py-2">+82 <span class="update_date">(2024.07.24)</span></h4>
  <ul class="list-disc ml-4 mb-5">
    <li>FOTA 식기세척기 아이콘 및 Wi-Fi 연결 시나리오 추가되었습니다.</li>
    <li>에어솔루션 모하비 제습기 FOTA PGM (SAA44779403, SAA44779404) 추가되었습니다.</li>
  </ul>
</template>
<script setup>
</script>
